import http from "../http-common";

class C19DataService {
  // getAll(params) {
  //   return http.get("/", { params });
  // }

  getRut(id) {
    return http.get(`/rut/${id}`);
  }
  
  getRespuesta(id) {
    return http.get(`/pdf/${id}`);
  }

  newResp(data) {
    return http.post("/newpdf/", data);
  }
  
  updateRut(data) {
    return http.post("/updaterut/", data);
  }

  getStats(data) {
    return http.post('/gstats/',data)
  }
  async getStatsCeco(data) {
    return await http.post('/gstatsceco/',data)
  }

  getRespuestas(data) {
    return http.post('/respuestas/', data)
  }
  // update(id, data) {
  //   return http.put(`/code/${id}`, data);
  // }
}

export default new C19DataService();